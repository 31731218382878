<template>
  <v-dialog
    v-model="dialog"
    width="800"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    style="background-color: blue; border-radius: 30px !important"
  >
    <v-form v-model="valid">
      <v-card flat style="overflow: hidden">
        <v-card-title class="secondary py-1" style="height: 40px">
          <span class="dialog-title">{{ $t("hospitalAdmin") }}</span>
          <v-spacer />
          <v-icon small color="primary" @click="$emit('false')"
            >mdi-window-close</v-icon
          >
        </v-card-title>
        <v-card-text color="primary" class="mt-5">
          <span
            >Welcome to admin registration form and setup process
            <span style="color: red">(required fields *)</span></span
          >
        </v-card-text>
        <v-row class="pa-6 px-8" dense>
          <v-col cols="12">
            <v-row class="pl-3 pb-4">
              <span style="font-size: 18px">Personal details</span>
            </v-row>
            <v-row dense class="py-2">
              <v-col class="pt-1" cols="12" lg="6" md="6" sm="12">
                <v-text-field
                  v-model="selectedUser.firstName"
                  label="Name *"
                  prepend-inner-icon="mdi-account mr-1"
                  dense
                  background-color="#E8E8E8"
                  rounded
                  outlined
                  style="border-radius: 12px !important; height: 40px"
                  :rules="[(rule) => !!rule || '']"
                ></v-text-field>
              </v-col>
              <v-col class="pt-1" cols="12" lg="6" md="6" sm="12">
                <v-text-field
                  v-model="selectedUser.lastName"
                  label="Surname *"
                  prepend-inner-icon="mdi-account mr-1"
                  dense
                  background-color="#E8E8E8"
                  rounded
                  outlined
                  style="border-radius: 12px !important; height: 40px"
                  :rules="[(rule) => !!rule || '']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="pb-2">
              <v-col class="pt-1 pb-2" cols="12" lg="6" md="6" sm="12">
                <validation-provider rules="required|email" :name="$t('email')" v-slot="{ errors }">
                  <v-text-field
                    v-model="selectedUser.email"
                    label="Email *"
                    prepend-inner-icon="mdi-email mr-1"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    style="border-radius: 12px !important; height: 40px"
                    :error-messages="emailError = errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col class="pt-1" cols="12" lg="6" md="6" sm="12">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-text-field
                    ref="phoneNumber"
                    v-model="selectedUser.phone"
                    validate-on-blur
                    label="Phone number *"
                    prepend-inner-icon="mdi-cellphone mr-4"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    style="border-radius: 12px !important; height: 40px"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row dense class="mt-0 pt-0" cols="12">
              <v-col cols="12" lg="6" md="6" sm="12">
                <v-radio-group
                  class="pl-10 pt-0"
                  v-model="selectedUser.gender"
                  row
                >
                  <v-radio label="Male" value="M"></v-radio>
                  <v-radio label="Female" value="F"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12">
                <BirthdatePicker
                  :inputValue.sync="selectedUser.birthdateAsString"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-row class="pa-4 pt-8">
                <span style="font-size: 18px">Work details</span>
              </v-row>
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-select
                  v-model="selectedUser.hospitalAssignedData.id"
                  label="Select Hosiptal *"
                  prepend-inner-icon="mdi-hospital-building mr-1"
                  :items="hospitals"
                  item-value="id"
                  item-text="hospitalName"
                  dense
                  :disabled="type !== 'add'"
                  background-color="#E8E8E8"
                  rounded
                  outlined
                  style="border-radius: 12px !important; height: 40px"
                  :rules="[(rule) => !!rule || '']"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-row class="pa-4 pt-8">
                <span style="font-size: 18px">Address details</span>
              </v-row>
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-text-field
                  ref="address"
                  @blur="locationDetails()"
                  v-model="selectedUser.address"
                  validate-on-blur
                  label="Address, State, Postal Code, City, Country *"
                  prepend-inner-icon="mdi-map-marker mr-4"
                  dense
                  background-color="#E8E8E8"
                  rounded
                  outlined
                  placeholder="Address, State, Postal Code, City, Country"
                  style="border-radius: 12px !important; height: 40px"
                  :rules="[(rule) => !!rule || '']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-actions>
          <Alert class="ml-6" v-if="showErrors" :title="title" :type="alertType"></Alert>
          <v-spacer></v-spacer>
          <div class="my-6">
            <v-btn class="table-create-button" @click="$emit('false')">
              <v-icon class="icon-cancel">mdi-window-close</v-icon>
              <span>{{ $t("cancel") }}</span>
            </v-btn>
            <v-btn
              class="table-create-button mx-6"
              :disabled="!valid"
              @click="createStaff"
            >
              <v-icon class="icon">mdi-plus</v-icon>
              <span>{{ $t("save") }}</span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import Alert from "@/views/Client/components/AlertDialog.vue";
import BirthdatePicker from "@/views/Client/components/BirthdatePicker";
import { phoneNumberFormatter } from '../../utils/luxon-formater'

export default {
  props: ["dialog", "type", "selectedItem"],
  components: {
    Alert,
    BirthdatePicker,
  },
  data: () => ({
    loading: false,
    title: "Something went wrong",
    alertType: false,
    snackColor: "",
    object: {
      addressLatitude: null,
      address: null,
      addressLongitude: null,
      birthdate: null,
      birthdateAsString: null,
      birthdateWithoutTime: null,
      birthdateWithTime: null,
      city: null,
      country: null,
      countryName: null,
      doctorMetadataReadDto: null,
      email: null,
      firstName: null,
      gender: null,
      hospitalAssignedData: {
        id: null,
      },
      id: null,
      lastName: null,
      medicalTeamsData: null,
      nurseMetadata: null,
      patientMetadata: null,
      phone: null,
      roleData: null,
      roleId: null,
      state: null,
      status: null,
      statusLongDescription: null,
      statusShortDescription: null,
      userSettingsJson: null,
      userTimezone: null,
      username: null,
    },
    address_info: {
      address: "",
      postal_code: "",
      city: "",
      state: "",
      country: "",
      lat: 42.001071,
      lng: 20.957554,
    },
    birthdate: "2002-01-01",
    hospitals: [],
    valid: false,
    addressToValidate: null,
    formattedAddress: null,
    timezone: null,
    emailError: false,
    showErrors: false,
    validationErrors: []
  }),
  computed: {
    ...mapState({
      hospitalsFromRepo: (state) => state.hospitals.hospitals,
      timeZones: (state) => state.verifiers.timeZones,
    }),
    selectedUser() {
      if (this.type === "edit") {
        return this.selectedItem;
      } else return this.object;
    },
  },
  async mounted() {
    await this.getHospitals();
    if (this.type === "edit") {
      this.addressData();
    }
  },
  methods: {
    async addressData() {
      this.address_info = {
        address: this.selectedUser.address,
        postal_code: this.selectedUser.zip,
        city: this.selectedUser.city,
        state: this.selectedUser.state,
        country: this.selectedUser.country,
        lat: this.selectedUser.addressLatitude,
        lng: this.selectedUser.addressLongitude,
      };
    },
    async timeZone(lat, lng) {
      let object = {
        lat: lat,
        lng: lng,
      };
      const time_zone = await this.$store.dispatch(
        "googleAPI/getTimeZone",
        object
      );
      return time_zone;
    },
    async locationDetails() {
      if (this.selectedUser.address) {
        const geo_location = await this.$store.dispatch(
          "googleAPI/getGeoLocation",
          this.selectedUser.address
        );
        const address_components = geo_location.address_components;
        this.address_info.lat = geo_location.geometry.location.lat;
        this.address_info.lng = geo_location.geometry.location.lng;
        this.formattedAddress = geo_location.formatted_address;
        this.address_info.address = this.formattedAddress;
        address_components.forEach((ac) => {
          if (ac.types.includes("administrative_area_level_1")) {
            this.address_info.state = ac.long_name;
          }
          if (ac.types.includes("locality")) {
            this.address_info.city = ac.long_name;
          }
          if (ac.types.includes("country")) {
            this.address_info.country = ac.long_name;
          }
          if (ac.types.includes("postal_code")) {
            this.address_info.postal_code = ac.long_name;
          }
        });
        if (this.address_info.lat !== null) {
          const tz = await this.timeZone(
            this.address_info.lat,
            this.address_info.lng
          );
          if (tz) {
            this.selectedUser.userTimezone = tz.zoneName;
          }
        }
      }
    },
    async assignAdminToHospital(userId) {
      if (this.selectedUser.hospitalAssignedData.id === -1) {
        this.$emit("false");
        return;
      }
      const body = {
        hospitalId: this.selectedUser.hospitalAssignedData.id,
        userId: userId,
      };
      await this.$store.dispatch("hospitals/assignAdminToHospital", body);
    },
    checkValid () {
      this.validationErrors = []
      if (!phoneNumberFormatter(this.selectedUser.phone)){
        this.validationErrors.push('phone')
      }
      if (this.emailError.length > 0) {
        this.validationErrors.push('email')
      }
      if(this.address_info.address === "") {
        this.validationErrors.push('address')
      }
      if (this.validationErrors.length > 0) {
        return false
      } else return true
    },
    async createStaff() {
      const valid = this.checkValid()
      if (!valid) {
        this.title = "Provided data is not valid: " + this.validationErrors.toString()
        this.alertType = 'error'
        this.showErrors = true
      }
      if (valid) {
        this.validationErrors = []
        this.loading = true;
        this.showErrors = false;
        const body = {
          firstName: this.selectedUser.firstName,
          lastName: this.selectedUser.lastName,
          phone: this.selectedUser.phone.split(" ").join(""),
          email: this.selectedUser.email,
          address: this.formattedAddress
            ? this.formattedAddress
            : this.address_info.address,
          zip: this.address_info.postal_code,
          city: this.address_info.city,
          state: this.address_info.state,
          country: this.address_info.country,
          addressLatitude: this.address_info.lat,
          addressLongitude: this.address_info.lng,
          username: this.selectedUser.email,
          password: "123456789",
          gender: this.selectedUser.gender,
          birthdate: this.selectedUser.birthdateAsString,
          roleId: 6,
          userTimezone: this.selectedUser.userTimezone,
        };
        switch (this.type) {
          case "add":
            this.createuser(body);
            break;
          case "edit":
            this.updateuser(body);
            break;
          default:
            break;
        }
      }
    },
    async updateuser(body) {
      (body.id = this.selectedUser.id),
        await this.$store
          .dispatch("users/updateUser", body)
          .then((res) => {
            this.$emit("updated", res);
          })
          .catch(() => {
            this.alertType = 'error'
            this.title = this.$t("adminCouldNotUpdate");
            this.showErrors = true
          });
    },
    async createuser(body) {
      await this.$store.dispatch("users/createUser", body).then(async (res) => {
        if (res.resFlag) {
          await this.assignAdminToHospital(res.data.id).then(() => {
            this.loading = false;
            this.$emit("updated", res);
          });
        }
      });
    },
    async getHospitals() {
      await this.$store.dispatch("hospitals/getHospitals").then(() => {
        if (this.hospitalsFromRepo !== null) {
          this.hospitals = this.hospitalsFromRepo;
        } else this.hospitals = [];
      });
    },
  },
};
</script>