<template>
  <div>
    <v-row class="mx-2">
      <v-col cols="12">
        <v-card flat style="border-radius: 12px">
          <v-card-title>
            <v-row>
              <v-col class="d-flex align-center justify-start" cols="8"
                ><h5 class="d-flex align-left">Hospital Admins</h5>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search-staff')"
                  single-line
                  hide-details
                  filled
                  dense
                  background-color="#E8E8E8"
                  rounded
                  class="table-search-button"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :loading="loading"
            :headers="headers"
            :headers-length="headers.length"
            :search="search"
            :items="users"
            sort-by="id"
            item-key="id"
            fixed-header
            style="cursor: pointer"
            :height="testHeight"
          >
            <template v-slot:item="{ item }">
              <tr @click="expand(item)">
                <td class="table-cols">
                  {{ item.status === 1 ? 'Active' : 'Inactive' }}
                </td>
                <td class="table-cols">
                  {{ item.firstName }} {{ item.lastName }}
                </td>
                <td class="table-cols">
                  {{ item.address }}
                </td>
                <td class="table-cols">
                   {{ item.email }}
                </td>
                <td class="table-cols">
                  {{ item.phone }}
                </td>
              </tr>
            </template>
            <template v-slot:[`footer.prepend`]="{}">
              <v-btn
                class="table-create-button"
                label="Test"
                @click="dialog = true; dialogtype = 'add'"
              >
                <v-icon class="icon">mdi-plus</v-icon>
                <span>{{ $t("addNewHospitalStaffTooltip") }}</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <AddHospitalAdminDialog
          v-if="dialog"
          :type="dialogtype"
          :dialog="dialog"
          :selectedItem="selectedItem"
          @false="closeDialog"
          @updated="updatedAdmin"
        />
        <ResponseDialog v-if="responseStatus" :status.sync="responseStatus" :title="responseTitle" :color="responseColor"></ResponseDialog>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import AddHospitalAdminDialog from "@/views/dialogs/HospitalAdminDialog.vue";
import ResponseDialog from "@/views/Client/components/Alert.vue";

export default {
  components: { AddHospitalAdminDialog, ResponseDialog },
  data() {
    return {
      responseStatus: false,
      responseTitle: "",
      responseColor: "",
      search: "",
      loading: true,
      dialog: false,
      users: [],
      headerClass: "black_inner--text !important",
      dialogtype: null,
      selectedItem: {},
    };
  },
  computed: {
    ...mapGetters({ roleName: "authentication/getRole" }),
    ...mapState({
      usersFromRepo: (state) => state.users.users,
      verifiersFromRepo: (state) => state.verifiers.verifiers,
    }),
    height() {
      return window.innerHeight - 250;
    },
    testHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return window.innerHeight - 320
        case 'sm': return window.innerHeight - 300
        case 'md': return window.innerHeight - 300
        case 'lg': return window.innerHeight - 280
        case 'xl': return window.innerHeight - 280
        default: return 0
      }
    },
    headers() {
      var headers = [];
      headers = [
        { text:this.$t("header-status"), value: "statusShortDescription", class: this.headerClass },
        { text: this.$t("header-full-name"), value: "fullname", class: this.headerClass },
        { text: this.$t("header-address"), value: "gender", class: this.headerClass },
        { text: this.$t("header-email"), value: "email", class: this.headerClass },
        { text: this.$t("header-phone"), value: "phone", class: this.headerClass },
      ];
      return headers;
    },
  },
  methods: {
    async expand(item) {
      this.dialogtype = 'edit'
      this.selectedItem = item
      this.dialog = true
    },
    calculateAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString.split("T")[0]);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    async getUsers() {
      await this.$store.dispatch("users/getUsers").then(() => {
        if (this.usersFromRepo !== null) {
          this.users = this.usersFromRepo.filter(
            (users) => users.roleData.roleName === "HOSPITAL_ADMIN"
          );
          this.users?.forEach((user) => {
            this.verifiersFromRepo.forEach((v) => {
              if (user.status === v.lowValue) {
                user.statusShortDescription = v.shortDescription;
                user.statusLongDescription = v.longDescription;
              }
            });
          });
        } else this.users = [];
        this.loading = false;
      });
    },
    async getVerifiers() {
      let header = {
        domainValue: "USER_STATUS",
      };
      await this.$store.dispatch("verifiers/getVerifiersForDomain", header);
    },
    adminDetails(item) {
      this.$router.push({
        name: "Admin Details",
        params: { id: `${item.id}` },
      });
    },
    async updatedAdmin (res) {
      if (res.resFlag) {
        this.responseColor = 'main_green'
        this.responseTitle = res.msg
        this.responseStatus = true
      } else {
        this.responseColor = 'main_red'
        this.responseTitle = res.msg
        this.responseStatus = true
      }
      this.dialog = false;
      this.selectedItem = {}
      this.dialogtype = null
      await this.getVerifiers();
      await this.getUsers();
    },
    async closeDialog() {
      this.dialog = false;
      this.selectedItem = {}
      this.dialogtype = null
      await this.getVerifiers();
      await this.getUsers();
    },
  },
  async mounted() {
    await this.getVerifiers();
    await this.getUsers();
  },
};
</script>